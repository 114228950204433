export const ShapesString = [
  '<svg width="91" height="91" viewBox="0 0 91 91" fill="none" xmlns="http://www.w3.org/2000/svg"><line y1="-2.5" x2="83.9958" y2="-2.5" transform="matrix(0.703703 0.710494 -0.710808 0.703386 15.8975 15.8872)" stroke="currentColor" stroke-width="4"/></svg>',
  '<svg width="91" height="91" viewBox="0 0 91 91" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.6194 16.0366C15.4204 35.928 18.0972 75.7108 30.3965 75.7108C45.7705 75.7108 35.3221 15.4428 53.5322 16.0366C71.8915 16.0366 60.6968 75.7108 74.7275 75.7108" stroke="currentColor" stroke-width="4"/></svg>',
  '<svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M57.547 30.7265C57.547 45.8245 45.3018 58.0658 30.1944 58.0658C15.0869 58.0658 2.8418 45.8245 2.8418 30.7265C2.8418 15.6285 15.0869 3.38721 30.1944 3.38721C45.3018 3.38721 57.547 15.6285 57.547 30.7265Z" stroke="currentColor" stroke-width="3"/></svg>',
  '<svg width="80" height="61" viewBox="0 0 80 61" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M76.5233 30.7265C76.5233 38.0215 72.6542 44.8014 66.0527 49.8385C59.449 54.8773 50.2167 58.0658 39.9165 58.0658C29.6162 58.0658 20.3839 54.8773 13.7802 49.8385C7.17867 44.8014 3.30957 38.0215 3.30957 30.7265C3.30957 23.4315 7.17867 16.6515 13.7802 11.6144C20.3839 6.57569 29.6162 3.38721 39.9165 3.38721C50.2167 3.38721 59.449 6.57569 66.0527 11.6144C72.6542 16.6515 76.5233 23.4315 76.5233 30.7265Z" stroke="currentColor" stroke-width="3"/></svg>',
  '<svg width="71" height="61" viewBox="0 0 71 61" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="2.91309" y="3.38721" width="65.4521" height="54.6785" stroke="currentColor" stroke-width="3"/></svg>',
  '<svg width="71" height="61" viewBox="0 0 71 61" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="2.63477" y="3.38721" width="65.4521" height="54.6785" rx="27.3393" stroke="currentColor" stroke-width="3"/></svg>',
  '<svg width="70" height="61" viewBox="0 0 70 61" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.75295 57.9166L34.8065 5.88553L64.86 57.9166H4.75295Z" stroke="currentColor"  stroke-width="3"/></svg>',
  '<svg width="101" height="113" viewBox="0 0 101 113" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M50.5812 1.1099L50.2491 0.814685L49.9169 1.10989L3.08962 42.7274L2.11011 43.5979L3.42056 43.6011L23.7696 43.6507L23.9958 111.038L23.9975 111.535L24.4941 111.536L50.6154 111.624L76.7538 111.712L77.2572 111.713L77.2555 111.21L77.0294 43.8422L97.4309 43.9198L98.7537 43.9248L97.765 43.046L50.5812 1.1099Z" stroke="currentColor" stroke-width="4"/></svg>',
  '<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 0.5L50 0.5L99.5 0.5V50V99.5H50H0.5L0.5 50L0.5 0.5Z" stroke="currentColor" stroke-width="7"/></svg>',
  '<svg width="160" height="100" viewBox="0 0 160 100" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M80 99.4104L0.943399 50L80 0.589622L159.057 50L80 99.4104Z" stroke="currentColor" stroke-width="6"/></svg>',
  '<svg width="90" height="50" viewBox="0 0 90 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.672015 49.5L15.372 0.5H89.328L74.628 49.5H0.672015Z" stroke="currentColor" stroke-width="3"/></svg>',
  '<svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="3.53475" width="37.3864" height="37.3864" transform="matrix(0.707264 0.706949 -0.707264 0.706949 33.25 1.13498)" stroke="currentColor" stroke-width="2"/></svg>',
  '<svg width="57" height="55" viewBox="0 0 57 55" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.7321 12.405L28.4727 3.18507L41.2133 12.405L41.22 12.4099L53.9285 21.6715L49.0936 36.6304L49.091 36.6384L44.2057 51.5819L28.4769 51.6082H28.4685L12.7397 51.5819L7.85442 36.6384L7.85183 36.6304L3.0169 21.6715L15.7254 12.4099L15.7321 12.405Z" stroke="currentColor" stroke-width="2"/></svg>',
  '<svg width="53" height="60" viewBox="0 0 53 60" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.4581 9.6216L26.1944 2.97246L37.9306 9.6216L49.5588 16.4559L49.666 29.9384L49.5588 43.4209L37.9305 50.2552L26.1944 56.9043L14.458 50.2551L2.82993 43.4209L2.72271 29.9384L2.82993 16.4559L14.4581 9.6216Z" stroke="currentColor" stroke-width="2"/></svg>',
  '<svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M30.8067 9.71091L34.7254 24.2588L35.0998 25.6486L36.4897 26.0226L51.0395 29.9384L36.4897 33.8542L35.0998 34.2282L34.7254 35.618L30.8067 50.1659L26.8879 35.618L26.5136 34.2282L25.1237 33.8542L10.5739 29.9384L25.1237 26.0226L26.5136 25.6486L26.8879 24.2588L30.8067 9.71091Z" stroke="currentColor" stroke-width="2"/></svg>',
  '<svg width="58" height="55" viewBox="0 0 58 55" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M28.75 7.13735L33.9643 20.5848L34.547 22.0877L36.1564 22.1772L50.56 22.9784L39.3792 32.0883L38.129 33.1069L38.5416 34.6659L42.2303 48.6066L30.1044 40.791L28.75 39.918L27.3957 40.791L15.2697 48.6066L18.9585 34.6659L19.371 33.1069L18.1208 32.0883L6.9401 22.9784L21.3437 22.1772L22.9531 22.0877L23.5358 20.5848L28.75 7.13735Z" stroke="currentColor" stroke-width="2"/></svg>',
  '<svg width="53" height="60" viewBox="0 0 53 60" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26.4727 7.93321L30.526 19.7601L31.2327 21.822L33.3717 21.4029L45.6432 18.9986L37.4255 28.4179L35.9916 30.0614L37.4255 31.705L45.6432 41.1243L33.3717 38.72L31.2327 38.3009L30.526 40.3628L26.4727 52.1897L22.4194 40.3628L21.7127 38.3009L19.5737 38.72L7.30215 41.1243L15.5199 31.705L16.9538 30.0614L15.5199 28.4179L7.30215 18.9986L19.5737 21.4029L21.7127 21.822L22.4194 19.7601L26.4727 7.93321Z" stroke="currentColor" stroke-width="2"/></svg>',
];
