import React, { useEffect, useState } from "react";
// import Cropper from "react-cropper";

//STYLE
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { getImageCropSrcSelector } from "@Store/draw/selectors";
import { setImageCropSrc } from "@Store/draw";
import useToggleMode from "@Hooks/useToggleMode";

import { Cropper } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css'

function CropImage({ canvas }) {
  const dispatch = useDispatch();
  const imageCropSrc = useSelector(getImageCropSrcSelector);
  let crop = null;
  const { toggleViewMode } = useToggleMode();

  // useEffect(() => console.log(imageCropSrc), [imageCropSrc]);
  const onCropperChange = (cropper) => {
    crop = cropper;
  };
  const onCancel = () => {
    dispatch(setImageCropSrc(null));
  };
  const onOk = () => {
    if (crop) {
      const base64 = crop.getCanvas()?.toDataURL();
      canvas.current?.addImageToBoard(base64, false, 3);
      dispatch(setImageCropSrc(null));
      toggleViewMode(true);
    }
  };
  return (
    <div
      // style={{
      //   width: window.screen.width,
      //   height: window.screen.height,
      // }}
      className={"crop-modal__container " + (imageCropSrc ? "show" : "")}
    >
      <div className="content">
        <div style={{ width: "100vw", height: "100vh" }}>
          <Cropper
            src={imageCropSrc}
            stencilProps={{
              movable: true,
              resizable: true
            }}
            onChange={onCropperChange}
          />
        </div>
        <div onClick={onOk} className="accept-btn">
          Tanam
        </div>
        <div onClick={onCancel} className="cancel-btn">
          İptal etmek
        </div>
      </div>
    </div>
  );
}

export default CropImage;
